import Button from 'components/atoms/button'
import theme from 'components/atoms/theme'
import Container from 'components/container'
import Layout from 'components/layout'
import Panel from 'components/molecules/panel'
import Seo from 'components/molecules/seo'
import Carousel from 'components/organisms/carousel'
import CTABlock from 'components/organisms/ctaBlock'
import Hero from 'components/organisms/hero'
import Panels from 'components/organisms/panels'
import Share from 'components/organisms/share'
import Section from 'components/style-guide-section'
import Swatch from 'components/swatch'
import { graphql } from 'gatsby'
import { PageProps } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import { useResourceMediaData } from 'hooks/useResourceMediaData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import React from 'react'
import styled from 'styled-components'
import { H1, H2, H3 } from 'styles/base'
import {
  ContentfulArticleConnection,
  ContentfulCtaBlockConnection,
  ContentfulHeroPanelConnection,
} from 'types/graphql-types'

const { colours } = theme

const Header = styled.div`
  margin-top: 5rem;
`

const ColourSwatches = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SubSection = styled(Container)`
  margin-bottom: 5rem;
`
type StyleGuideProps = PageProps & {
  data: {
    allContentfulHeroPanel: ContentfulHeroPanelConnection
    allContentfulArticle: ContentfulArticleConnection
    allContentfulCtaBlock: ContentfulCtaBlockConnection
    wholesaleCoffee: ContentfulCtaBlockConnection
  }
}

const StyleGuide = ({ data }: StyleGuideProps): JSX.Element => {
  const heroData = getLocalNodes(data.allContentfulHeroPanel.nodes)
  const articlesData = getLocalNodes(data.allContentfulArticle.nodes)
  const ctaData = getLocalNodes(data.allContentfulCtaBlock.nodes)
  const wholesaleCoffeeData = getLocalNodes(data.wholesaleCoffee.nodes)

  const {
    body: { body: wholesaleCoffeeBody },
    cta: wholesaleCoffeeCTA,
    images: wholesaleCoffeeImages,
  } = wholesaleCoffeeData[0]

  const {
    body: { body: ctaBody },
    cta,
  } = ctaData[0]
  return (
    <Layout>
      <Seo title="Style Guide" />
      <div>
        <Header data-test-id="style-guide-header">
          <Container>
            <H1>Style Guide</H1>
            <p>
              A working site style guide built to manage design and code quality
              control.
            </p>
          </Container>
        </Header>
        <Section title="Colours">
          <Container>
            <ColourSwatches>
              <Swatch title="Primary" colour={colours.primary} />
              <Swatch title="Primary - Hover" colour={colours.primaryTint1} />
            </ColourSwatches>
            <ColourSwatches>
              <Swatch
                title="Secondary"
                colour={colours.secondary}
                darkText
                shadow
              />
            </ColourSwatches>
            <ColourSwatches>
              <Swatch title="Tertiary" colour={colours.tertiary} />
              <Swatch title="Tertiary - Hover" colour={colours.lightTint1} />
            </ColourSwatches>
          </Container>
        </Section>
        <Section title="Typography">
          <Container>
            <H1>Heading 1</H1>
            <H2>Heading 2</H2>
            <H3>Heading 3</H3>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <h6>Heading 6</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat.
            </p>
            <p>
              Ut wisi enim ad minim veniam, quis nostrud{' '}
              <strong>exerci tation ullamcorper suscipit </strong>lobortis nisl
              ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
              dolor in hendrerit <em>in vulputate velit esse molestie </em>
              consequat, vel illum dolore eu feugiat nulla facilisis at vero
              eros et accumsan et iusto odio dignissim qui blandit praesent
              luptatum zzril delenit augue duis dolore te feugait nulla
              facilisi. Nam liber tempor cum soluta nobis eleifend option
              congue.
            </p>
            <p>
              Nihil imperdiet doming id{' '}
              <a href="#" title="Link">
                quod mazim placerat
              </a>{' '}
              facer possim assum. Typi non habent claritatem insitam; est usus
              legentis in iis qui facit eorum claritatem. Investigationes
              demonstraverunt lectores legere me lius quod ii legunt saepius.
              Claritas est etiam processus dynamicus, qui sequitur mutationem
              consuetudium lectorum. Mirum est notare quam littera gothica, quam
              nunc putamus parum claram, anteposuerit litterarum formas
              humanitatis per seacula quarta decima et quinta decima. Eodem modo
              typi, qui nunc nobis videntur parum clari, fiant sollemnes in
              futurum.
            </p>
          </Container>
        </Section>
        <Section title="Buttons">
          <SubSection>
            <h5>Primary</h5>
            <Button>Default</Button>
            {` `}
            <Button active>Hover / Active</Button>
            {` `}
            <Button disabled>Disabled</Button>
          </SubSection>
        </Section>
        <Section title="Hero">
          <Hero {...heroData[0]} image={heroData[0].image.fluid} />
        </Section>

        <Section title="Panels">
          <Panels>
            <Panel
              title={useResourceTextData('home.findacafetitle', 'Find a cafe')}
              body={useResourceTextData('home.findacafebody')}
              {...useResourceMediaData('home.findacafeimage')}
              to="/find-a-cafe"
            />
            <Panel
              title={useResourceTextData(
                'home.findaroasterytitle',
                'Find a roastery'
              )}
              body={useResourceTextData('home.findaroasterybody')}
              {...useResourceMediaData('home.findaroasteryimage')}
              to="/find-a-roastery"
            />
          </Panels>
        </Section>

        <Section title="CTA Block">
          <SubSection>
            <h5>Without Images</h5>
          </SubSection>
          <CTABlock body={ctaBody} cta={cta} />
          <SubSection>
            <h5>With Images</h5>
          </SubSection>
          <CTABlock
            body={wholesaleCoffeeBody}
            cta={wholesaleCoffeeCTA}
            images={wholesaleCoffeeImages}
          />
        </Section>
        <Section title="Carousel">
          <Carousel
            intro={useResourceTextData('home.latestarticlesintro')}
            introCta={{
              text: useResourceTextData(
                'home.latestarticlesbrowse',
                'Browse articles'
              ),
              to: '/community',
            }}
            items={articlesData}
          />
        </Section>
        <Section title="Social Sharing Buttons">
          <Container>
            <Share />
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default StyleGuide

export const pageQuery = graphql`
  query StyleGuideQuery {
    allContentfulHeroPanel(filter: { key: { eq: "home.hero" } }) {
      nodes {
        id
        title
        image {
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        body {
          body
        }
        cta {
          ...RegionalLink
        }
        node_locale
      }
    }
    allContentfulCtaBlock(filter: { key: { eq: "home.pullquote" } }) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        node_locale
      }
    }
    allContentfulArticle(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        id
        slug
        heroImage {
          fluid {
            ...Image
          }
          title
        }
        node_locale
        title
        category {
          title
          slug
        }
      }
    }
    wholesaleCoffee: allContentfulCtaBlock(
      filter: { key: { eq: "home.wholesale-and-coffee" } }
    ) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        images {
          media {
            title
            fluid {
              srcSet
              ...Image
            }
          }
        }
        node_locale
      }
    }
  }
`
